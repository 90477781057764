<template>
  <div class="divBox">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="摊位" v-model="searchParam.stallName" />
        <v-input label="联系人" v-model="searchParam.contact" />
        <v-input label="联系电话" v-model="searchParam.contactNumber" />
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
      </template>
    </v-list>
    <v-dialog
      :title="title"
      v-model="show"
      @confirm="confirm"
      sureTxt="保存"
      :showCancelButton="false"
      oStyle="justify-content: flex-end;"
      @close="closeDialog"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="填写摊号">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.stallName"
          ></v-input> </el-form-item
        ><el-form-item label="联系人">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.contact"
          ></v-input> </el-form-item
        ><el-form-item label="联系方式">
          <div class="form-item">
            <v-input
              :disabled="isEdit"
              clearable
              class="filter-item"
              v-model="temp.contactNumber"
              @blur="handelInputBlur"
            ></v-input>
            <v-button
              style="margin-left: 10px"
              text="编辑"
              v-if="temp.id"
              @click="handelClick"
            ></v-button>
          </div> </el-form-item
        ><el-form-item label="主营">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.laserLevel"
          ></v-input> </el-form-item
        ><el-form-item label="上传照片">
          <v-upload :limit="1" :imgUrls.sync="temp.stallPhoto" />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import { setAESCbcEncrypt } from "@/utils/common";
import {
  getListUrl,
  addStallsUrl,
  compileStallsUrl,
  deleteStallsUrl,
  compileStallsEdit,
} from "./api.js";

export default {
  name: "jinzhongAgriculturalTrade",
  data() {
    return {
      proposeAnnexFiles: "",
      title: "",
      show: false,
      searchParam: {
        stallName: null,
        contact: null,
        contactNumber: null,
      },
      temp: {
        id: null,
        stallName: null,
        stallPhoto: null,
        laserLevel: null,
        contact: null,
        contactNumber: null,
      },
      tableUrl: getListUrl,
      headers: [
        {
          prop: "id",
          label: "编号",
        },
        {
          prop: "stallName",
          label: "摊位",
        },
        {
          prop: "laserLevel",
          label: "主营",
        },
        {
          prop: "contact",
          label: "联系人",
        },
        {
          prop: "contactNumber",
          label: "联系电话",
        },
      ],
      isEdit: false,
    };
  },
  mounted() {},
  methods: {
    handelInputBlur() {
      if (this.temp.id) {
        const params = {
          id: this.temp.id,
          contactNumber: setAESCbcEncrypt(this.temp.contactNumber),
        };
        this.$axios.post(compileStallsEdit, params).then((res) => {
          if (res.code == 200) {
            this.isEdit = true;
            this.$message.success(res.msg);
          }
        });
      }
    },
    handelClick() {
      this.temp.contactNumber=""
      this.isEdit = false;
    },
    closeDialog() {
      this.temp.id = null;
      this.temp.stallName = null;
      this.temp.stallPhoto = null;
      this.temp.laserLevel = null;
      this.temp.contact = null;
      this.temp.contactNumber = null;
      this.$refs.list.search();
    },
    confirm() {
      if (!this.temp.stallName) {
        this.$message.error("请输入摊位名称！");
        return;
      }
      if (!this.temp.contact) {
        this.$message.error("请输入联系人！");
        return;
      }
      if (!this.temp.contactNumber) {
        this.$message.error("请输入联系方式！");
        return;
      }
      if (!this.temp.laserLevel) {
        this.$message.error("请输入主营内容！");
        return;
      }
      if (!this.temp.stallPhoto) {
        this.$message.error("请上传图片！");
        return;
      }
      if (this.temp.id) {
        delete this.temp.contactNumber;
      } else {
        this.temp.contactNumber = setAESCbcEncrypt(this.temp.contactNumber);
      }
      let params = this.temp;
      let url = this.temp.id ? compileStallsUrl : addStallsUrl;
      this.$axios.post(`${url}`, params).then((res) => {
        if (res.code == 200) {
          this.show = false;
          this.$message.success(res.msg);
        }
      });
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios
          .post(`${deleteStallsUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toAdd() {
      this.isEdit = false;
      this.temp.id = null;
      this.temp.stallName = null;
      this.temp.stallPhoto = null;
      this.temp.laserLevel = null;
      this.temp.contact = null;
      this.temp.contactNumber = null;
      this.title = "新增";
      this.show = true;
    },
    toEdit(row) {
      this.isEdit = true;
      this.temp = JSON.parse(JSON.stringify(row));
      this.title = "编辑";
      this.show = true;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
  .form-item {
    display: flex;
  }
}
</style>
